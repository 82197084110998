import { lazy } from "react";
import { pageRoutes } from "./page.routes";
import Home from "../Components/Modules/Dashboard/Home";
import Login from "../Components/Modules/Auth/Login";
import CourseListing from "../Components/Modules/Dashboard/Courses/CourseListing";
import EditCourse from "../Components/Modules/Dashboard/Courses/EditCourse";
import CourseDetail from "../Components/Modules/Dashboard/Courses/CourseDetail";
import CreateCourse from "../Components/Modules/Dashboard/Courses/CreateCourse";
import UserListing from "../Components/Modules/Dashboard/Users/UserListing";
import EditUser from "../Components/Modules/Dashboard/Users/EditUser";
import UserDetail from "../Components/Modules/Dashboard/Users/UserDetail";
import CreateUser from "../Components/Modules/Dashboard/Users/CreateUser";
import AllPayments from "../Components/Modules/Dashboard/Payment/AllPayments";
import Chat from "../Components/Modules/Dashboard/Chat";
import AllAnnouncement from "../Components/Modules/Dashboard/Announcement/AllAnnouncement";
import CreateAnnouncement from "../Components/Modules/Dashboard/Announcement/CreateAnnouncement";
import TestListing from "Components/Modules/Dashboard/Test/TestListing";
import EditTest from "Components/Modules/Dashboard/Test/EditTest";
import TestDetail from "Components/Modules/Dashboard/Test/TestDetail";
import CreateTest from "Components/Modules/Dashboard/Test/CreateTest";
import Settings from "Components/Modules/Dashboard/Settings";
import N400Form from "Components/Modules/Dashboard/N400/N400Form";
import NotFound from "Components/Modules/Dashboard/NotFound";
import ManageQuiz from "Components/Modules/Dashboard/Courses/ManageQuiz";
import Instructions from "Components/Instructions";
import AppTutorial from "Components/Modules/Dashboard/AppTutorial";
import LawyerListing from "Components/Modules/Dashboard/Lawyer/LawyerListing";
import LawyersRequests from "Components/Modules/Dashboard/Lawyer/LawyersRequests";
import LawyersRejected from "Components/Modules/Dashboard/Lawyer/LawyersRejected";
import Payment from "Components/Modules/Dashboard/LawyerPayments/Payment";


/* Routes used for Authentication are auth */
/* Routes accessed through Sidebar are primary */
/* All Other Routes are Secondary */

const routes = {
    auth: [
        { path: pageRoutes.login, component: <Login /> },
        { path: pageRoutes?.deleteAccount, component: <Instructions /> },
        { path: pageRoutes.dashboard?.notFound, component: <NotFound /> },
    ],
    dashboard: [
        { path: pageRoutes.dashboard?.home, component: <Home /> },
        { path: pageRoutes.dashboard?.payment, component: <Payment /> },
        { path: pageRoutes.dashboard?.howToUseApp, component: <AppTutorial /> },

        { path: pageRoutes.dashboard?.settings, component: <Settings /> },
        { path: pageRoutes.dashboard?.n400, component: <N400Form /> },

        // Lawyer Modules
        { path: pageRoutes.dashboard?.Lawyer?.lawyerListing, component: <LawyerListing /> },
        { path: pageRoutes.dashboard?.Lawyer?.lawyerRequests, component: <LawyersRequests /> },
        { path: pageRoutes.dashboard?.Lawyer?.blockedListing, component: <LawyersRejected /> },

        // Courses 
        { path: pageRoutes.dashboard?.Courses?.courseListing, component: <CourseListing /> },
        { path: pageRoutes.dashboard?.Courses?.editCourse, component: <CreateCourse /> },
        { path: pageRoutes.dashboard?.Courses?.courseDetail, component: <CourseDetail /> },
        { path: pageRoutes.dashboard?.Courses?.createCourse, component: <CreateCourse /> },
        { path: pageRoutes.dashboard?.Courses?.manageQuiz, component: <ManageQuiz /> },

        // Users
        { path: pageRoutes.dashboard?.Users?.userListing, component: <UserListing /> },

        // Tests
        { path: pageRoutes.dashboard?.Tests?.readingTest, component: <TestListing type={1} /> },
        { path: pageRoutes.dashboard?.Tests?.writingTest, component: <TestListing type={2} /> },
        { path: pageRoutes.dashboard?.Tests?.editTest, component: <CreateTest /> },
        { path: pageRoutes.dashboard?.Tests?.testDetail, component: <TestDetail /> },
        { path: pageRoutes.dashboard?.Tests?.createTest, component: <CreateTest /> },
        // Payments
        { path: pageRoutes.dashboard?.Payment?.payments, component: <AllPayments /> },
        // Chat
        { path: pageRoutes.dashboard?.chat?.chat, component: <Chat /> },
        // Announcements
        { path: pageRoutes.dashboard?.announcement?.allAnnouncement, component: <AllAnnouncement /> },
        { path: pageRoutes.dashboard?.announcement?.createAnnouncement, component: <CreateAnnouncement /> },
        { path: pageRoutes.dashboard?.announcement?.editAnnouncement, component: <CreateAnnouncement /> },





    ],
}
export {
    routes as DynamicRoutes
}