import { DatePicker, Divider } from 'antd'
import React, { useEffect } from 'react'
import DynamicCard from '../../Layout.Components/Custom/DynamicCard'
import DynamicTable from '../../Layout.Components/Custom/DynamicTable';
import { getColumn } from '../../../Utility/Columns';
import Button from '../../Dynamics/Button';
import { DollarOutlined, NotificationOutlined, UserOutlined, VideoCameraOutlined } from '@ant-design/icons';
import CheckEmpty from 'Components/Empty';
import { getAllCourses } from 'API/Services/courses.service';
import { useState } from 'react';
import Loader from 'Components/Loader/Loader';
import PopularVideos from 'Components/DashbaordComponents/PopularVideos';
import UserDashboard from 'Components/DashbaordComponents/UserDashboard';
import { getAdminKpis } from 'API/Services/users.service';
import { formatMoney, utility } from 'Utility';
import { getAllNotifications } from 'API/Services/notification.service';
import SidebarNotification from 'Components/DashbaordComponents/SidebarNotification';
import { pageRoutes } from 'Routing/page.routes';

const Home = () => {
  const [data, setData] = useState({})
  const getKpis = async () => {
    const result = await getAdminKpis()
    setData(result)
  }

  useEffect(() => {
    getKpis()
  }, [])
  return (
    <div className='grid grid-cols-12 gap-5'>
      <div className='col-span-8 '>
        <div className='grid grid-cols-12 gap-5 '>
          <div className='col-span-12 bg-white p-3 rounded-md'>
            <p className='font-semibold text-[22px]'>Overview</p>
            <Divider className='my-3' />
            <Loader keys={['getAdminKpis']}>
              <div className='grid grid-cols-12 gap-3'>
                {[{ heading: "Users", desc: data?.users || 'N-A', mainDivCss: "bg-white col-span-4 border", image: <UserOutlined className='text-[25px]' /> }
                  , { heading: "Videos", desc: data?.videos || 'N-A', mainDivCss: "bg-white col-span-4 border", image: <VideoCameraOutlined className='text-[25px]' /> }
                  , { heading: "Earnings", desc: formatMoney(data?.earning), mainDivCss: "bg-white col-span-4 border", image: <DollarOutlined className='text-[25px]' /> }]?.map((item, index) => <DynamicCard mainDivCss='w-full' cardType='dashboardCard' {...item} />)}
              </div>
            </Loader>
          </div>
          <div className='col-span-12'>
            <div className='w-full bg-white p-3 rounded-md'>
              <div className='flex justify-between'>
                <p className='font-semibold text-[22px] flex  gap-3'>Students <UserOutlined /> </p>
                <Button onClick={() => utility?.navigate(pageRoutes?.dashboard?.Users?.userListing)} buttonType='blue'>See All</Button>
              </div>
              <UserDashboard />
            </div>
            <PopularVideos />
          </div>
        </div>
      </div>
      <div className='col-span-4   rounded-md  '>
        <Loader keys={['getAllNotifications', 'markAllRead']}>
          <SidebarNotification />
        </Loader>
      </div>
    </div>
  )
}

export default Home