import React, { useEffect, useState } from 'react'
import { Header } from '../../../Layout.Components'
import { Divider } from 'antd'
import DynamicTable from '../../../Layout.Components/Custom/DynamicTable'
import { getColumn } from '../../../../Utility/Columns'
import pagination from 'Components/HighOrderFunctions/Pagination'
import { FormController } from 'Components/FormControl'
import { useForm } from 'react-hook-form'
import { SearchOutlined } from '@ant-design/icons'
import { getPayments } from 'API/Services/users.service'
import Loader from 'Components/Loader/Loader'

const AllPayments = pagination((props) => {
  const { currentPage, setTotal, setCurrentPage } = props
  const Form = useForm()
  const [data, setData] = useState(false)
  const [queryFilters, setQueryFilters] = useState({
    search: '',
    date: "",
  })
  const fields = [
    // { type: 10, name: 'search', state: setQueryFilters, stateKey: "search", userOnChange: () => { setCurrentPage(1) }, rules: { required: false }, label: 'Search', placeholder: 'Search by student name', use: 'antd', span: "col-span-3", className: "h-[40px]", icon: <SearchOutlined /> },
    // { type: 3, name: 'date', label: "", state: setQueryFilters, stateKey: "date", rules: { required: false }, userOnChange: () => { setCurrentPage(1) }, label: "Filter by date", placeholder: "Select date", use: 'antd', span: "col-span-4", className: "h-[40px] w-full" },
  ]
  const fetchPayments = async () => {
    const result = await getPayments(
      {
        name: queryFilters?.search,
        date: queryFilters?.date,
        offset: ((currentPage - 1) * 10),
        limit: 10
      }
    )
    setTotal(result?.count)
    setData(result?.Payments)

  }
  useEffect(() => {
    fetchPayments()
  }, [queryFilters?.search, queryFilters?.date, currentPage])
  return (
    <Loader keys={['getPayments']}>
      <div className='bg-white p-3 rounded-md'>
        <div>
          <Header heading={'Payments'} />
          <Divider className='my-2' />
        </div>
        <FormController
          Form={Form}
          fields={fields}
          onSubmit={() => { }}
          className={'grid grid-cols-12 gap-2'}
        />
        <DynamicTable column={getColumn('paymentListing')} pagination={false} data={data || []}
          className={`border border-custom-gray-200  rounded-md mt-3`} />

      </div>
    </Loader>

  )
})

export default AllPayments