import React, { useEffect, useState } from 'react'
import ChatSidebar from './Components/ChatSidebar'
import MainChat from './Components/MainChat'
import { io } from "socket.io-client";
import Loader from 'Components/Loader/Loader';
import { ChatService } from 'API/Services/chat.service';


const Chat = () => {

  const [selectedConversation, setSelectedConversation] = useState(null)
  const [messages, setMessages] = useState([])
  const [conversations, setConversations] = useState([])

  const CS = new ChatService({
    callback: ({ conversations = null, Messages = null, Conversation = {} }) => {
      if (conversations)
        setConversations(conversations)
      if (Messages) {
        setMessages(Messages)
        setSelectedConversation(Conversation)
      }
    }
  })

  return (
    <div className="grid grid-cols-12 gap-2 h-[85vh] overflow-hidden w-[100%] border border-custom-gray-200 rounded-md p-2  bg-[white]">
      <div className='col-span-4 h-[100%] p-1 overflow-scroll' style={{ borderRight: "1px solid #F2F4F7" }}>
        <ChatSidebar CS={CS} conversations={conversations} />
      </div>
      <div className='col-span-8  h-full overflow-scroll p-1'>
        {selectedConversation
          ? <MainChat
            setMessages={setMessages}
            CS={CS}
            conversation={selectedConversation}
            messages={messages} />
          : <h4 className='flex justify-center items-center h-full'>Select any conversation!</h4>}
      </div>
    </div >
  )

}

export default Chat
