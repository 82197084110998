import { getAllUsers } from 'API/Services/users.service'
import Button from 'Components/Dynamics/Button'
import CheckEmpty from 'Components/Empty'
import pagination from 'Components/HighOrderFunctions/Pagination'
import DynamicCard from 'Components/Layout.Components/Custom/DynamicCard'
import Loader from 'Components/Loader/Loader'
import React, { useEffect, useState } from 'react'

const UserDashboard = pagination((props) => {
    const { currentPage, setTotal, setCurrentPage } = props
    const [data, setData] = useState([])
    const fetchUsers = async () => {
        const result = await getAllUsers({
            offset: currentPage - 1,
            limit: 10
        })
        setData(result?.Users)
    }
    useEffect(() => {
        fetchUsers()
    }, [])
    return (
        <div className='flex flex-col gap-2 my-2'>
            <Loader keys={['getAllUsers']}>
                <CheckEmpty arr={data?.length}>
                    {
                        data?.map((item, index) => <DynamicCard mainDivCss='w-full' cardType='userListingCard' data={item} />
                        )
                    }
                </CheckEmpty>
            </Loader>
        </div>)
})
export default UserDashboard