
import { catchAsyncErrors } from 'API/Handlers';
import { getRequest, postRequest, putRequest } from 'API/api.methods';
import { apiRoutes } from 'API/api.routes';
import { setPageLoader, verification } from 'Redux/Features';
import { pageRoutes } from 'Routing/page.routes';
import { addQueryParams, utility } from 'Utility';
import { Message, Storage } from 'Utility/Services';
const getAllUsers = catchAsyncErrors(async (query) => {
    utility.dispatch(setPageLoader({ page: 'getAllUsers', flag: true }))
    let result = await getRequest({
        endPoint: addQueryParams(apiRoutes?.Users?.getAllUsers, query),
    })
    utility.dispatch(setPageLoader({ page: 'getAllUsers', flag: false }))
    return result
})
const getPayments = catchAsyncErrors(async (query) => {
    utility.dispatch(setPageLoader({ page: 'getPayments', flag: true }))
    let result = await getRequest({
        endPoint: addQueryParams(apiRoutes?.Users?.getPayments, query),
    })
    utility.dispatch(setPageLoader({ page: 'getPayments', flag: false }))
    return result
})
const getAdminKpis = catchAsyncErrors(async (query) => {
    utility.dispatch(setPageLoader({ page: 'getAdminKpis', flag: true }))
    let result = await getRequest({
        endPoint: addQueryParams(apiRoutes?.Users?.getKpis, query),
    })
    utility.dispatch(setPageLoader({ page: 'getAdminKpis', flag: false }))
    return result
})
const sendCoins = catchAsyncErrors(async (id, body) => {
    utility.dispatch(setPageLoader({ page: 'sendCoins', flag: true }))
    let result = await putRequest({
        endPoint: addQueryParams((apiRoutes?.Users?.updateCoin + id)),
        body: body
    })
    utility.dispatch(setPageLoader({ page: 'sendCoins', flag: false }))
    if(result?.success) Message?.success('Coins send successfully!')
    return result
})
export { sendCoins, getPayments, getAllUsers, getAdminKpis }