import { SendOutlined } from '@ant-design/icons'
import { ChatService } from 'API/Services/chat.service'
import { ImageImports } from 'Assets/Images'
import Button from 'Components/Dynamics/Button'
import { FormService } from 'Components/FormControl'
import Loader from 'Components/Loader/Loader'
import { socket } from 'Libraries/socket'
import { validations } from 'Utility/Validations'
import { Divider } from 'antd'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

const MainChat = ({ setMessages, messages = [], conversation = {}, CS }) => {

    const Form = useForm()
    const fields = [
        { type: 1, name: 'text', rules: { required: true, validate: validations?.checkEmptySpace }, labelCss: 'font-normal text-custom-gray-500 mb-1', placeholder: 'Send Message..', span: 'col-span-12', use: 'antd', className: "text-xs h-12 " },
    ]
    const User = JSON.parse(localStorage.getItem('user'))

    if (socket)
        socket.onmessage = async (event) => {
            const data = JSON.parse(event.data);
            console.log({ data })
            switch (data.type) {
                case 'message:receive':
                    if (data?.sender_id != User?.id)
                        setMessages(prev => ([...prev, { ...data }]))
                // await CS.selectConversation({ id: data?.conversation_id }, { loader: false })
            }
        };
    const scrollToBottom = () => {
        const container = document.getElementById("messageContainer");
        container.scrollTop = container.scrollHeight;
    };
    useEffect(() => {
        scrollToBottom();
    }, [messages]);
    return (
        <Loader keys={['Messages']}>
            <div className='w-full h-[100%]'>
                {/* Chat Header */}
                <div className='py-2 flex gap-1 items-center bg-[white] ' style={{ borderBottom: "1px solid lightGray" }} >
                    <img src={conversation?.participant_1?.profile_picture || conversation?.participant_2?.profile_picture || ImageImports?.noProfilePic} className='w-[35px] h-[35px] rounded-full object-cover' />
                    <p className='font-semibold'>{conversation?.participant_1?.name ? conversation?.participant_1?.name : conversation?.participant_2?.name ? `${conversation?.participant_2?.name} (Lawyer)` : ''} </p>
                </div>
                {/* Chatting */}
                <div id="messageContainer" className='flex flex-col w-full gap-2 h-[69vh] overflow-scroll'>
                    {messages?.map((item, index) => {
                        let is_user = false
                        if (item?.sender)
                            is_user = item?.sender?.id == User?.id ? true : false
                        if (item?.sender_id)
                            is_user = item?.sender_id == User?.id ? true : false
                        return (
                            <div className='flex p-2 w-[full]' style={{ justifyContent: !is_user ? 'start' : 'end' }}>
                                <div className='w-[60%]' >
                                    <div className='w-full flex flex-col ' style={{ alignItems: !is_user ? 'start' : 'end', }}>
                                        <p className=' border border-custom-gray-200 rounded-md p-1 text-[12px]'
                                            style={{ background: !is_user ? '#475467' : 'white', color: !is_user ? 'white' : 'black', }} >
                                            {item?.text}
                                        </p>
                                        <p className='text-end text-[9px] my-1'>{moment(item?.created_at).format('hh:mm a - DD/MM/YYYY')}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                {/* Send Message text Field and  */}
                <div>
                    <form
                        className='relative'
                        onSubmit={Form.handleSubmit(({ text }) => {
                            setMessages(prev => ([...prev, { text, conversation_id: conversation?.id, sender_id: User?.id }]))
                            CS.sendMessage(text, conversation, Form);
                            Form.reset()
                        })}>
                        <div className='flex gap-1  sticky'>
                            <FormService
                                Form={Form}
                                fields={fields}
                                onSubmit={() => { }}
                                className={'flex w-[100%] flex-col gap-5'}
                            />
                            <Button htmlType='submit' additionalClassName='h-[50px] w-[60px] bg-custom-primary-500 text-white' >
                                <SendOutlined className='' /> Send
                            </Button>
                        </div>

                    </form>
                </div>
            </div>
        </Loader>)
}

export default MainChat