import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { ImageImports } from 'Assets/Images';
import Image from '../Image';
import Loader from 'Components/Loader/Loader';
const CustomModal = (props) => {
    const { modalTitle, showStateReason = true, showTitleIcon = true, modalIcon = ImageImports?.deleteModalIcon, label, open, close, callApi, field, stateReason = 'Are you sure, you want to delete this?', stateReasonStyle } = props
    return (
        <div className=''>
            <Modal centered width={props?.width || 350} title={
                <div className='flex items-center mt-[0px] gap-1'>
                    {showTitleIcon && <Image src={modalIcon} width={40} className={"ml-[-10px]"} />}
                    <p className='text-[18px]'>{modalTitle}</p>
                </div>} open={open}
                onCancel={close} footer={null}>
                <Loader keys={['grantAdminMemberShip', 'revokeAdminMemberShip', 'updateImmigrationOfficer', 'handleOfficerRequest', 'deleteOfficer',
                    'deleteCourseAttachment', 'createCourseAttachment', 'updateCourseAttachment',
                    'updateQuizQuestion', 'deleteQuestion', 'createQuiz', 'addQuestion',
                    'deleteQuiz', 'updateN400Question', 'uploadApi', 'createN400Question',
                    'deleteCourse', 'deleteTestById', 'createCourseItem', 'updateCourseItem',
                    'deleteCourseItem', 'deleteN400Question']}>
                    <p className='font-bold text-xs my-3'>{label}</p>
                    {showStateReason && <p className={`text-[11px] text-custom-gray-900 ${stateReasonStyle}`}>{stateReason}</p>}
                    {
                        field && field
                    }
                </Loader>
            </Modal>
        </div>
    );
};
export default CustomModal;