import React, { useEffect, useState } from 'react'
import { Header } from '../../../Layout.Components'
import { Divider, Modal } from 'antd'
import DynamicTable from '../../../Layout.Components/Custom/DynamicTable'
import { getColumn } from '../../../../Utility/Columns'
import { useForm } from 'react-hook-form'
import pagination from 'Components/HighOrderFunctions/Pagination'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { FormController } from 'Components/FormControl'
import { getAllUsers } from 'API/Services/users.service'
import Loader from 'Components/Loader/Loader'
import Button from 'Components/Dynamics/Button'
import CustomModal from 'Components/Dynamics/Modals'
import { ImageImports } from 'Assets/Images'
import UploadButton from 'Components/UploadButton/UploadButton'
import { uploadApi } from 'API/Services/generic.service'
import { Message } from 'Utility/Services'
import { addImmigrationOfficer, deleteOfficer, getOfficers, getOfficersRequests, handleOfficerRequest, updateImmigrationOfficer } from 'API/Services/ImmigrationOfficer.service'
import { validations } from 'Utility/Validations'

const LawyersRequests = pagination((props) => {
    const { currentPage, setTotal, setCurrentPage } = props
    const Form2 = useForm()
    const [image, setImage] = useState('')
    const [modal, setModal] = useState({
        add: false,
        reject: false
    })
    const [modalData, setModalData] = useState({})
    const [data, setData] = useState([])
    const [queryFilters, setQueryFilters] = useState({
        search: '',
        date: "",
        userType: ""
    })
    const handleRequest = async (id, body) => {
        const result = await handleOfficerRequest(id, body)
        if (body?.action == 'approve') fetchOfficersRequests()
    }
    const fetchOfficersRequests = async () => {
        const result = await getOfficersRequests({
            name: queryFilters?.search,
            date: queryFilters?.date,
            tyoe: queryFilters?.type,
            offset: currentPage - 1,
            limit: 10
        })
        setData(result?.Officers)
        setTotal(result?.count)
    }
    const handleModal = (type) => {
        setModal((prev) => { return { ...prev, [type]: !modal[type] } })
    }
    const modalFields = [
        { type: 1, disabled: true, name: 'name', label: 'Name', labelCss: 'font-normal text-custom-gray-500 mb-1', placeholder: 'Enter name', span: 'col-span-12', use: 'antd', className: "text-xs h-12 " },
        { type: 1, disabled: true, name: 'email_address', label: 'Email Address', labelCss: 'font-normal text-custom-gray-500 mb-1', placeholder: 'Enter Email Address', span: 'col-span-12', use: 'antd', className: "text-xs h-12 " },
        { type: 11, disabled: true, name: 'description', label: 'Description', labelCss: 'font-normal text-custom-gray-500 mb-1', placeholder: 'Enter Description', span: 'col-span-12', use: 'antd', className: "text-xs h-12 " },
        { type: 1, disabled: true, name: 'phone_number', label: 'Phone Number', labelCss: 'font-normal text-custom-gray-500 mb-1', placeholder: 'Enter Phone Number', span: 'col-span-12', use: 'antd', className: "text-xs h-12 " },
        { type: 1, disabled: true, name: 'lawyer_id', label: 'Lawyer-ID', labelCss: 'font-normal text-custom-gray-500 mb-1', placeholder: 'Enter Officer ID', span: 'col-span-12', use: 'antd', className: "text-xs h-12 " },
        { type: 1, disabled: true, name: 'state', label: 'State', labelCss: 'font-normal text-custom-gray-500 mb-1', placeholder: 'Enter State', span: 'col-span-12', use: 'antd', className: "text-xs h-12 " },
        // { type: 4, name: `featured`, label: <span className='text-xs text-custom-gray-700'>{'Featured Lawyer'} </span>, rules: { required: false }, className: "w-full" },
    ]
    const handleSubmit = async (val) => {
        if (!image) return Message?.error('Kindly Upload Image!')
        if (modalData) {
            const result = await updateImmigrationOfficer(modalData?.id, {
                ...val,
                profile_picture: image
            })
            if (result?.success) handleModal('add')
        } else {
            const result = await addImmigrationOfficer({
                ...val,
                profile_picture: image
            })
            if (result?.success) handleModal('add')
        }
        fetchOfficersRequests()
    }
    const handleOfficerFields = () => {
        ['name', 'email_address', 'phone_number', 'description', 'lawyer_id', 'state', 'featured'
        ].map((item) => {
            Form2?.setValue(item, modalData?.[item] ? modalData?.[item] : '')
        })
        console.log(modalData)
        setImage(modalData?.profile_picture)
    }
    useEffect(() => {
        if (modalData) handleOfficerFields()
        else {
            setImage('')
        }
    }, [modalData])
    useEffect(() => {
        fetchOfficersRequests()
    }, [queryFilters?.search, queryFilters?.date, queryFilters?.userType, currentPage])
    return (
        <div className='bg-white p-3 rounded-md'>
            <div className='mb-4'>
                <Header heading={'Lawyers Requests'} />
            </div>
            <Loader keys={['getOfficers', 'handleOfficerRequest']}>
                <DynamicTable column={getColumn('lawyerRequests', handleModal, setModalData, handleRequest)} pagination={false} data={data}
                    className={`border border-custom-gray-200  rounded-md mt-3`} />
            </Loader>
            {/* Lawyer Profile Detail */}
            <Modal width={600} title={'Immigration Lawyer Detail'} open={modal?.add} onCancel={() => { handleModal('add') }} footer={null}>
                <Loader keys={['handleRequest']}>
                    <div className='flex justify-between  items-center my-4'>
                        <div className='flex flex-col'>
                            <p className='font-bold text-[11px]'>{'Cover Photo'}</p>
                            <p className='text-custom-gray-500 text-[10px]'>{'This will be set as Lawyer cover photo.'}</p>
                        </div>
                        <div className='flex justify-between w-[50%]  items-center mb-[20px]'>
                            {image ? <img src={image || ImageImports?.noProfilePic} className={'rounded-full h-[80px] w-[80px] object-cover'} /> : <div></div>}
                        </div>
                    </div>
                </Loader>
                <Loader keys={['addImmigrationOfficer', 'updateImmigrationOfficer']}>
                    <FormController
                        Form={Form2}
                        fields={modalFields}
                        onSubmit={handleSubmit}
                        className={'flex w-[100%] flex-col gap-5'}

                    />
                </Loader>
            </Modal>
            {/* Reject Lawyer Request*/}
            <CustomModal
                width={450}
                stateReason={'Are you sure, you want to Reject this Request?'}
                modalTitle='Reject '
                open={modal?.reject}
                close={() => { handleModal('reject') }}
                field={<div className='w-full mt-5'>
                    <Button onClick={async () => { await handleRequest(modalData?.id, { action: 'reject' }); handleModal('reject'); fetchOfficersRequests() }} additionalClassName='w-full' buttonType='darkRed'>
                        Reject
                    </Button></div>
                }
            />

        </div>
    )
})

export default LawyersRequests