import { uploadApi } from 'API/Services/generic.service'
import { createN400Question, updateN400Question } from 'API/Services/n400.service'
import { uploadRequest } from 'API/api.methods'
import Button from 'Components/Dynamics/Button'
import { FormController } from 'Components/FormControl'
import { categoriesN400 } from 'Utility'
import { validations } from 'Utility/Validations'
import { Divider } from 'antd'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

const CreateShortQuestion = ({ data = false, close, getN400Questions }) => {
    const Form = useForm()
    const [audioUrl, setAudioUrl] = useState(false)
    const handleFile = async (file, type) => {
        const result = await uploadApi([file], 5)
        if (result) setAudioUrl(result)
    }
    const questionField = [
        { type: 1, name: `question`, label: 'Question', rules: { required: true }, placeholder: 'Write question', use: 'antd', span: "col-span-12", },
        { type: 8, name: `category`, label: 'Category', options: categoriesN400, rules: { required: true }, placeholder: 'Category', use: 'antd', span: "col-span-12", },
        { type: 1, name: `order`, label: 'Order No.', rules: { required: true, validate: validations?.check_decimalNumber }, placeholder: 'Order number', use: 'antd', span: "col-span-12", },
        { type: 102, label: 'Upload Question Audio', name: `audio`, handleFile: handleFile, audioUrl: audioUrl, setaudioUrl: setAudioUrl, span: "col-span-12", audio: data?.audio_url || false, },
    ]
    const handleSubmit = async (val) => {
        let body = {
            ...val,
            type: 'TEXT',
            audio_url: audioUrl ? audioUrl : null
        }
        // if (!audioUrl) delete body.audio_url
        if (!data) await createN400Question(body)
        else await updateN400Question(data?.id, body)
        close()
    }
    useEffect(() => {
        if (data) {
            setAudioUrl(data?.audio_url)
            Form?.setValue('question', data?.question)
            Form?.setValue('category', data?.category)
            Form?.setValue('order', data?.order)

        } else { Form?.setValue('question', ''); setAudioUrl('') }
    }, [data])
    return (
        <div className='border border-custom-gray-300 rounded-md p-3 w-full bg-[white] mt-3'>
            <FormController
                Form={Form}
                onSubmit={handleSubmit}
                submitButton={<Button additionalClassName='w-full col-span-12' htmlType='submit' buttonType='lightBlue'>
                    {data ? 'Update Form Field' : 'Create Form Field'}
                </Button>}
                fields={questionField}
                className={'grid grid-cols-12 gap-2 '}
            />
            <Divider />
        </div>
    )
}

export default CreateShortQuestion