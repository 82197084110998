import { getRequest, postRequest } from "API/api.methods"
import { socket } from "Libraries/socket"
import { LocalStorage } from "Utility/Services"

const { apiRoutes } = require("API/api.routes")
const { catchAsyncErrors } = require("API/Handlers")
const { setPageLoader } = require("Redux/Features")
const { utility } = require("Utility")

export class ChatService {

    constructor(metaData = {}) { Object.assign(this, metaData) }

    conversations = catchAsyncErrors(async () => {

        utility.dispatch(setPageLoader({ page: 'Conversations', flag: true }))
        let { Conversations } = await getRequest({ endPoint: apiRoutes.chat.conversations })
        this.callback({ conversations: Conversations })
        utility.dispatch(setPageLoader({ page: 'Conversations', flag: false }))

    })

    selectConversation = catchAsyncErrors(async (conversation, options = {}) => {

        const { loader = true } = options;
        if (loader) {
            utility.dispatch(setPageLoader({ page: 'Conversations', flag: true }))
            utility.dispatch(setPageLoader({ page: 'Messages', flag: true }))
        }
        const Storage = new LocalStorage()
        const user = Storage.get('user')
        socket.send(JSON.stringify({
            type: 'join-room',
            data: { conversation_id: conversation?.id, name: user?.name }
        }))
        let { Conversation, Messages } = await getRequest({ endPoint: apiRoutes.chat.messages + conversation?.id })
        this.callback({ Conversation: conversation, Messages })
        if (loader) {
            utility.dispatch(setPageLoader({ page: 'Conversations', flag: false }))
            utility.dispatch(setPageLoader({ page: 'Messages', flag: false }))
        }

    })

    sendMessage = catchAsyncErrors(async (text, conversation, form) => {

        const User = JSON.parse(localStorage.getItem('user'))
        // utility.dispatch(setPageLoader({ page: 'Conversations', flag: true }))
        utility.dispatch(setPageLoader({ page: 'Messages', flag: true }))
        await postRequest({
            endPoint: apiRoutes.chat.send_message,
            body: {
                text,
                conversation: conversation?.id
            }
        })
        // await this.selectConversation(conversation)
        socket.send(JSON.stringify({
            type: 'message:send',
            data: {
                conversation_id: conversation?.id,
                message: text,
                text,
                sender_id: User?.id
            }
        }))
        form.reset()
        // utility.dispatch(setPageLoader({ page: 'Conversations', flag: false }))
        utility.dispatch(setPageLoader({ page: 'Messages', flag: false }))

    })


}