import { Divider, Popover } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Menu } from 'antd';
import { pageRoutes } from '../../../Routing/page.routes'
import Image from '../../Dynamics/Image';
import { ImageImports } from '../../../Assets/Images';
import { sidebarItems } from '../../../Utility'
import Button from '../../Dynamics/Button';
import { UserOutlined } from '@ant-design/icons';
import { Storage } from 'Utility/Services';


const Sidebar = ({ path }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const onClick = (e) => {
    navigate(e?.key)
  };


  return (
    <div className='bg-white flex flex-col p-3 h-screen overflow-auto no-scroll relative' style={{ borderRight: "  1px  solid #EAECF0" }}>
      <div className='flex gap-2 items-center justify-center'>
        <Image src={ImageImports?.Logo} width={130} className=" mt-2" />
      </div>
      <Divider className='my-0' />
      <div className='flex flex-col justify-between gap-3 h-[80%] overflow-scroll pb-3 mt-6'>
        <Menu
          onClick={onClick}
          className={`text-xs text-custom-gray-600 border-none flex flex-col justify-center gap-2`}
          selectedKeys={location?.pathname}
          mode="inline"
          items={sidebarItems?.admin}
        />
      </div>
      <div className='left absolute bottom-[10px] w-[90%] flex justify-center'>
        <Button additionalClassName='w-full text-[red] font-bold bg-[white]' additio onClick={() => { Storage?.clear(); navigate(pageRoutes?.login); }} >
          <UserOutlined />
          Logout
        </Button>
      </div>
    </div>

  );
};
export default Sidebar;




