import React, { useEffect, useState } from 'react'
import { Header } from '../../../Layout.Components'
import { Divider } from 'antd'
import DynamicTable from '../../../Layout.Components/Custom/DynamicTable'
import { getColumn } from '../../../../Utility/Columns'
import Loader from 'Components/Loader/Loader'
import { FormController } from 'Components/FormControl'
import { useForm } from 'react-hook-form'
import Button from 'Components/Dynamics/Button'
import { useParams } from 'react-router-dom'
import { createTest, getTestById, updateTest } from 'API/Services/test.service'
import UploadButton from 'Components/UploadButton/UploadButton'
import { uploadApi } from 'API/Services/generic.service'
import { Message } from 'Utility/Services'
import { validations } from 'Utility/Validations'

const CreateTest = () => {
  const { id } = useParams()
  const Form = useForm()
  const [checkBox, setCheckBox] = useState(false)
  const [file, setFile] = useState('')
  const [audioUrl, setaudioUrl] = useState(false)
  const handleFile = async (file, type) => {
    const result = await uploadApi([file], 7)
    if (result) setaudioUrl(result)
  }
  const fields = [
    {
      type: 8, divClassName: 'flex justify-between items-center',
      options: [{ title: 'Reading Test', value: 1 },
      { title: 'Writing Test', value: 2 }],
      labelCss: "w-full", placeholder: "Select Category", divider: false, name: 'type', label: 'Category', rules: { required: true }, use: 'antd', span: "", onChange: (value) => { }, className: "w-[100%] text-[11px] h-[32px]"
    },
    { type: 11, divClassName: 'flex justify-between items-center', placeholder: "Enter Text", labelClassName: 'w-full', divider: false, name: 'text', label: 'Text', rules: { required: true }, use: 'antd', span: "", onChange: (value) => { }, className: "w-[100%] text-[11px] h-[32px]" },
    {
      type: 4, divClassName:
        'flex justify-between items-center',
      value: false,
      placeholder: "Enter Text",
      labelClassName: 'w-full',
      divider: false, name: 'use_ai',
      label: 'Text to Speech',
      rules: { required: false }, use: 'antd', span: "",
      userOnChange: (e) => {
        setCheckBox(!e?.target?.value);
      }, className: "w-[100%] text-[11px] h-[32px]"
    },
    {
      type: 102, disabled: checkBox,
      divClassName: 'flex justify-between items-center',
      handleFile: handleFile, file: file, setFile: setFile,
      audioUrl: audioUrl, setaudioUrl: setaudioUrl, labelClassName: 'w-full', divider: false, name: 'text', label: 'Custom Audio ', rules: { required: true }, use: 'antd', span: "", onChange: (value) => { }, className: "w-[100%] text-[11px] h-[32px]"
    },
    { type: 1, divClassName: 'flex justify-between items-center', placeholder: "Enter Order No.", labelClassName: 'w-full', divider: false, name: 'order', label: 'Order No.', rules: { required: true, validate: validations?.check_decimalNumber }, use: 'antd', span: "", onChange: (value) => { }, className: "w-[100%] text-[11px] h-[32px]" },
  ]
  const fetchTest = async () => {
    const result = await getTestById(id)
    Form?.setValue('type', result?.type)
    Form?.setValue('use_ai', result?.use_ai || false)
    setCheckBox(result?.use_ai || false)
    Form?.setValue('text', result?.text)
    Form?.setValue('order', result?.order)
    setaudioUrl(result?.audio_url)
  }
  const submitFunc = async (val) => {
    let body = { ...val, audio_url: audioUrl }
    if (!val?.use_ai & !audioUrl) return Message?.error('Kindly Upload Audio')
    if (id) await updateTest(id, body)
    else await createTest(body)
  }
  useEffect(() => {
    if (id) {
      fetchTest()
    }
  }, [id])
  useEffect(() => {
    if (checkBox) {
      setaudioUrl('')
    }
  }, [checkBox])

  return (
    <Loader keys={['getTestById', 'updateTest', 'createTest', 'uploadApi']}>
      <div className='bg-[white] p-4 rounded-md'>
        <Header heading={id ? 'Edit Test' : 'Create Test'} />
        <Divider className='my-2' />
        <div className=' grid grid-cols-12'>
          <div className='col-span-8'>
            <FormController
              formType={'antd'}
              Form={Form}
              onSubmit={submitFunc}
              className="flex flex-col gap-10"
              submitButton={
                <div className='flex justify-end'>
                  <Button buttonType='blue' htmlType='submit' additionalClassName='font-semibold text-[13px]'>
                    {id ? 'Update Test' : 'Create Test'}
                  </Button>
                </div>
              }
              fields={fields} />
          </div>
        </div>
      </div >
    </Loader>

  )
}

export default CreateTest