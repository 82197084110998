import React, { useEffect, useState } from 'react'
import { Header } from '../../../Layout.Components'
import { Divider, Modal } from 'antd'
import DynamicTable from '../../../Layout.Components/Custom/DynamicTable'
import { getColumn } from '../../../../Utility/Columns'
import { useForm } from 'react-hook-form'
import pagination from 'Components/HighOrderFunctions/Pagination'
import { DollarCircleFilled, SearchOutlined, SendOutlined } from '@ant-design/icons'
import { FormController } from 'Components/FormControl'
import { getAllUsers, sendCoins } from 'API/Services/users.service'
import Loader from 'Components/Loader/Loader'
import Button from 'Components/Dynamics/Button'
import { validations } from 'Utility/Validations'

const UserListing = pagination((props) => {
  const { currentPage, setTotal, setCurrentPage } = props
  const Form2 = useForm()
  const [modal, setModal] = useState(false)
  const [modalData, setModalData] = useState({})
  const Form = useForm()
  const [data, setData] = useState([])
  const [queryFilters, setQueryFilters] = useState({
    search: '',
    date: "",
    userType: ""
  })
  const fields = [
    { type: 10, name: 'search', state: setQueryFilters, stateKey: "search", userOnChange: () => { setCurrentPage(1) }, rules: { required: false }, label: 'Search', placeholder: 'Search by student name', use: 'antd', span: "col-span-3", className: "h-[40px]", icon: <SearchOutlined /> },
    // { type: 8, name: 'courseType', label: "", state: setQueryFilters, stateKey: "userType", rules: { required: false }, userOnChange: () => { setCurrentPage(1) }, label: "User type", placeholder: 'Search by student type', use: 'antd', span: "col-span-4", className: "w-full", size: "small", mainDivCss: "bg-white h-[40px]", options: [{ value: "paid", title: "Paid" }, { value: "free", title: "Free" }] },
    // { type: 3, name: 'date', label: "", state: setQueryFilters, stateKey: "date", rules: { required: false }, label: "Filter by date", userOnChange: () => { setCurrentPage(1) }, placeholder: "Select date", use: 'antd', span: "col-span-4", className: "h-[40px] w-full" },
  ]
  const modalFields = [
    { type: 10, name: 'points', label: 'Enter Amount', rules: { required: true, validate: validations?.check_number }, labelCss: 'font-normal text-custom-gray-500 mb-1', placeholder: 'Enter Amount', span: 'col-span-12', use: 'antd', className: "text-xs h-12 ", icon: <DollarCircleFilled /> },
  ]
  const fetchUsers = async () => {
    const result = await getAllUsers({
      name: queryFilters?.search,
      date: queryFilters?.date,
      type: queryFilters?.type,
      offset: ((currentPage - 1) * 10),
      limit: 10
    })
    setTotal(result?.count)
    setData(result?.Users)
  }
  const handleSubmit = async (val) => {
    const result = await sendCoins(modalData?.id, val)
    setModal(false)
  }
  useEffect(() => {
    fetchUsers()
  }, [queryFilters?.search, queryFilters?.date, queryFilters?.userType, currentPage])
  useEffect(() => {
    Form2?.reset()
  }, [modal])
  return (
    <div className='bg-white p-3 rounded-md'>
      <div>
        <Header heading={'Student Listing '} />
        <Divider className='my-2' />
      </div>
      <FormController
        Form={Form}
        fields={fields}
        onSubmit={() => { }}
        className={'grid grid-cols-12 gap-2'}
      />
      <Loader keys={['getAllUsers']}>
        <DynamicTable column={getColumn('adminPatientsListing', setModal, setModalData)} pagination={false} data={data}
          className={`border border-custom-gray-200  rounded-md mt-3`} />
      </Loader>
      <Modal width={600} title={`Send Gift To ${modalData?.name}`} open={modal} onCancel={() => { setModal(!modal) }} footer={null}>
        <Loader keys={['sendCoins']}>
          <FormController
            Form={Form2}
            fields={modalFields}
            onSubmit={handleSubmit}
            className={'flex w-[100%] flex-col gap-5'}
            submitButton={<Button htmlType='submit' buttonType='blue'>
              Send <SendOutlined />
            </Button>
            }
          />
        </Loader>
      </Modal>
    </div>
  )
})

export default UserListing