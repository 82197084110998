import React, { useEffect, useState } from 'react'
import { Header } from '../../../Layout.Components'
import { Divider, message, Modal } from 'antd'
import DynamicTable from '../../../Layout.Components/Custom/DynamicTable'
import { getColumn } from '../../../../Utility/Columns'
import { useForm } from 'react-hook-form'
import pagination from 'Components/HighOrderFunctions/Pagination'
import { BankFilled, FileOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { FormController } from 'Components/FormControl'
import { getAllUsers } from 'API/Services/users.service'
import Loader from 'Components/Loader/Loader'
import Button from 'Components/Dynamics/Button'
import CustomModal from 'Components/Dynamics/Modals'
import { ImageImports } from 'Assets/Images'
import UploadButton from 'Components/UploadButton/UploadButton'
import { uploadApi, uploadInvoiceData } from 'API/Services/generic.service'
import { Message } from 'Utility/Services'
import { addImmigrationOfficer, deleteOfficer, getOfficers, getOfficersPaymentRequest, handleOfficerPayment, updateImmigrationOfficer } from 'API/Services/ImmigrationOfficer.service'
import { validations } from 'Utility/Validations'
import { downloadFile } from 'Utility'

const Payment = pagination((props) => {
    const { currentPage, setTotal, setCurrentPage } = props
    const Form = useForm()
    const Form2 = useForm()
    const [image, setImage] = useState('')
    const [modalData, setModalData] = useState({})
    const [modal, setmodal] = useState(false)
    const [invoiceData, setInvoiceData] = useState({ image: '', pdf: '' })
    const [data, setData] = useState([])
    const [queryFilters, setQueryFilters] = useState({
        status: 'REQUESTED'
    })
    console.log({ invoiceData })
    const fields = [
        {
            type: 8, name: 'status', state: setQueryFilters,
            stateKey: "status",
            userOnChange: () => { setCurrentPage(1) }, rules: { required: false },
            label: 'Filter by Request Type',
            options: [{ value: "REQUESTED", title: "New Payment Requests" }, { value: "PAID", title: "Previously Paid Request " }],
            placeholder: 'Filter by Request Type', use: 'antd', span: "col-span-3",
            className: "h-[40px]",
        },
    ]
    const fetchPaymentRequests = async () => {
        const result = await getOfficersPaymentRequest({
            status: queryFilters?.status,
            offset: currentPage - 1,
            limit: 10
        })
        setData(result?.Requests)
        setTotal(result?.count)
    }
    const modalFields = [
        { type: 1, name: 'payment_comment', label: 'Comment', labelCss: 'font-normal text-custom-gray-500 mb-1', placeholder: 'Enter comments', span: 'col-span-12', use: 'antd', className: "text-xs h-12 " },
    ]
    const handleFile = async (file, type) => {
        if (type == 'pdf') {
            const result = await uploadInvoiceData([file, { consultation_id: modalData?.consultation }], 19)
            setInvoiceData(prev => { return { ...prev, pdf: result } })
        }
        else {
            const result = await uploadInvoiceData([file, { consultation_id: modalData?.consultation }], 18)
            setInvoiceData(prev => { return { ...prev, image: result } })
        }
    }
    const handleSubmit = async (val) => {
        if (invoiceData?.image || invoiceData?.pdf) {
            const result = await handleOfficerPayment(modalData?.id, { payment_comment: val?.payment_comment, payment_picture: invoiceData?.image, payment_invoice: invoiceData?.pdf })
            if (result?.success) {
                setModalData({})
                setmodal(false)
                setInvoiceData({ pdf: '', image: '' })
                fetchPaymentRequests()
            }
        }
        else Message?.error("Kindly upload Invoice")
    }
    const handleOfficerFields = () => {
        ['name', 'email_address', 'phone_number', 'description', 'lawyer_id', 'state', 'featured'
        ].map((item) => {
            Form2?.setValue(item, modalData?.[item] ? modalData?.[item] : '')
        })
        setImage(modalData?.profile_picture)
    }
    useEffect(() => {
        if (modalData) handleOfficerFields()
        else {
            setImage('')
        }
    }, [modalData])
    useEffect(() => {
        fetchPaymentRequests()
    }, [queryFilters?.status, currentPage])
    return (
        <div className='bg-white p-3 rounded-md'>
            <div>
                <Header heading={'Payment Management '} >
                </Header>
                <Divider className='my-2' />
            </div>
            <FormController
                Form={Form}
                fields={fields}
                onSubmit={() => { }}
                className={'grid grid-cols-12 gap-2'}
            />
            <Loader keys={['getOfficersPaymentRequest']}>
                <DynamicTable column={getColumn('payment_management',
                    () => { setmodal(true) },
                    (data) => {
                        setModalData(data);
                        Form2?.setValue('payment_comment', data?.payment_comment)
                        setInvoiceData((prev) => {
                            return {
                                ...prev, pdf: data?.payment_invoice || '',
                                image: data?.payment_picture || ''
                            }
                        })
                    })
                } pagination={false} data={data}
                    className={`border border-custom-gray-200  rounded-md mt-3`} />
            </Loader>
            <Modal width={600} title={'Payment Request Detail'} open={modal} onCancel={() => { setmodal(false); setModalData({}) }} footer={null}>
                <p className='text-[20px] font-bold mt-3'>Amount to be paid: ${modalData?.amount_to_transfer}</p>
                <p className='text-[14px] mt-1'>Bank Name: <BankFilled /> {modalData?.officer?.bank}</p>
                <p className='text-[14px] mt-1'>Accout Number: {modalData?.officer?.bank_acc_no}</p>
                <Divider />
                <Loader keys={['addImmigrationOfficer', 'updateImmigrationOfficer', 'uploadApi', 'handleOfficerPayment']}>
                    <div className='flex w-[full]   mb-[20px] flex-col'>
                        <p className='text-[13px] font-bold mb-2'>Upload Invoice Image:</p>
                        {invoiceData?.image ? <img src={invoiceData?.image || ImageImports?.noProfilePic} className={'rounded-full h-[80px] w-[80px] object-cover'} /> : <div></div>}
                        <div className='flex gap-3  w-full '>
                            {
                                invoiceData?.image ?
                                    <Button buttonType='darkRed' additionalClassName='mt-2 ml-2' onClick={() => setInvoiceData(prev => { return { ...prev, image: '' } })}>{'Delete'}</Button>
                                    : <UploadButton fileType={'image'} setImage={(file) => handleFile(file, 'image')} button={<Button buttonType='lightBlue'>{image ? 'Update' : 'Upload'}</Button>} />
                            }
                        </div>
                    </div>
                    <div className='flex w-[full]   mb-[20px] flex-col'>
                        <p className='text-[13px] font-bold mb-2'>Upload Invoice PDF:</p>
                        {invoiceData?.pdf ? <Button onClick={() => downloadFile(invoiceData?.pdf)} buttonType='lightBlue' additionalClassName='w-fit'><FileOutlined />  Uploaded  Pdf </Button> : <div></div>}
                        <div className='flex gap-3  w-full '>
                            {
                                invoiceData?.pdf ?
                                    <Button buttonType='darkRed' additionalClassName='mt-2 ml-2' onClick={() => setInvoiceData(prev => { return { ...prev, pdf: '' } })}>{'Delete'}</Button>
                                    :
                                    <UploadButton fileType={'licenseUrl'} setImage={(file) => handleFile(file, 'pdf')} button={<Button buttonType='lightBlue'>{image ? 'Update' : 'Upload'}</Button>} />
                            }
                        </div>
                    </div>
                    <FormController
                        Form={Form2}
                        fields={modalFields}
                        onSubmit={handleSubmit}
                        submitButton={
                            modalData?.status !== 'PAID' ?
                                <Button htmlType='submit' additionalClassName='font-semibold w-full h-[45px] text-[14px] rounded-[10px] shadow-xl' buttonType='blue'>
                                    Submit
                                </Button>
                                :
                                <Button disabled={true} additionalClassName='font-semibold w-full h-[45px] text-[14px] rounded-[10px] shadow-xl' buttonType='blue'>
                                    Already Paid
                                </Button>
                        }
                        className={'flex w-[100%] flex-col gap-5 mt-2'}
                    />
                </Loader>
            </Modal>
        </div>
    )
})

export default Payment