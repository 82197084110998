import React from 'react'
import Image from '../../Dynamics/Image'
import { ImageImports } from '../../../Assets/Images'
import { useNavigate } from 'react-router-dom'
import { Description } from './Description'
import { DefaultTags } from '../../Dynamics/Tags'
import { extractDateFromTimeString } from 'Utility'

const DynamicCard = (props) => {
    const renderCard = {
        dashboardCard: <DashboardCard {...props} />,
        userListingCard: <UserListingCard {...props} />,
        notificationsListing: <NotificationsListing {...props} />
    }
    return renderCard[props?.cardType]
}

export default DynamicCard

const DashboardCard = (props) => {
    return (
        <div className={` bg-custom-gray-50 shadow-xl rounded-md flex justify-between gap-3 p-4  ${props?.mainDivCss}`}>
            <div className='flex flex-col justify-evenly'>
                <p className={`font-bold text-[18px] ${props?.headingCss}`}>{props?.heading}</p>
                <DefaultTags type='blue' text='' label={props?.desc} />
            </div>
            {props?.image}
        </div>
    )
}
const UserListingCard = (props) => {
    const navigate = useNavigate()
    const { data, } = props
    return <div className={`grid grid-cols-12 items-center p-3 bg-custom-gray-50 rounded-md border border-custom-gray-100 shadow-sm`}>
        <div className='col-span-9'>
            <div className='grid grid-cols-12 gap-3 items-center'>
                <div className='col-span-6 flex gap-3 items-start'>
                    <img src={data?.profile_picture || ImageImports?.noProfilePic} className={`object-cover h-[50px] w-[50px]  rounded-full`} />
                    <div className='flex flex-col justify-center'>
                        <p className=' font-semibold text-[16px]'>{data?.name}</p>
                        <div className='flex-col'>
                            <p className='text-[11px] text-custom-gray-600 '>
                                {data?.email_address}
                            </p>
                            <p className='text-[11px] text-custom-gray-600 '>
                                {''}
                            </p>
                        </div>
                    </div>
                </div>
                {/* TAGS-----> */}
                <div className='col-span-4 flex gap-3'>
                    <DefaultTags type='blue' text='Subsccription' label='Vip' />
                    <DefaultTags type='red' text='Joined Date' label={extractDateFromTimeString(data?.created_at, 'words')} />
                </div>
            </div>
        </div>
    </div>

}

const NotificationsListing = (props) => {
    const { data } = props
    return <div className={`flex justify-between items-center p-3 bg-[white] rounded-md border border-custom-gray-100 shadow-md`}>
        <div className='flex  gap-3 items-center'>
            <div className='flex flex-col justify-center'>
                <p className=' font-semibold text-[16px]'>{data?.text}</p>
                <div className='flex flex-col'>
                    {
                        data?.sender && <p className='text-[11px] text-custom-gray-600 '>
                            {data?.sender?.email_address}
                        </p>
                    }
                    <p className='text-[11px] text-custom-gray-600 '>
                        {extractDateFromTimeString(data?.created_at, 'words')}
                    </p>
                </div>
            </div>
        </div>
        {
            !data?.is_read &&
            <div className='bg-[#24D164] h-[10px] w-[10px] rounded-full'>
            </div>
        }


    </div>

}