import { ChatService } from 'API/Services/chat.service'
import { ImageImports } from 'Assets/Images'
import Image from 'Components/Dynamics/Image'
import Loader from 'Components/Loader/Loader'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const ChatSidebar = ({ CS, conversations }) => {

    const pageLoader = useSelector(state => state?.utils?.pageLoader)
    
    useEffect(() => {
        CS.conversations()
    }, [])

    const GetParticipant = (chat) => {
        if (chat?.participant_1)
            return chat?.participant_1
        if (chat?.participant_2)
            return chat?.participant_2
    }

    return (
        <div className='flex flex-col gap-1 h-full overflow-scroll'>
            <Loader keys={['Conversations']}>
                {
                    conversations?.map((chat, index) => {
                        return (<div onClick={() => CS.selectConversation(chat)} className='border border-custom-gray-100 rounded-md p-2 flex gap-2 shadow-sm cursor-pointer' >
                            <img src={GetParticipant(chat)?.profile_picture || ImageImports?.noProfilePic} className='w-[35px] h-[35px] rounded-full object-cover' />
                            <div className='flex flex-col w-full '>
                                <div className='flex gap-2 w-full justify-between'>
                                    <p className='font-semibold text-[13px]'>{GetParticipant(chat)?.name}</p>
                                    {/* {
                                    <span className='bg-[#c11339] text-[8px] p-1 rounded-[100%] text-[white] w-[20px] h-[20px] text-center'>10</span>
                                } */}
                                </div>
                                <p className='text-[9px] text-custom-gray-700 w-[60%] truncate'>{chat?.last_message?.text || ''}</p>
                            </div>
                        </div>)
                    })
                }
            </Loader>
        </div>)
}


export default ChatSidebar